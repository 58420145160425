import axios from "axios";
const url = "https://api-new.ibot.bet";
// const url = "http://localhost:8000";
// const url = "http://68.183.177.221";

const apiAxios = axios.create({
  // baseURL: process.env.REACT_APP_API_URL || 'https://api.ibot.bet/',
  baseURL: url,
});
// baseURL: process.env.REACT_APP_API_URL || 'https://api.ibot.bet/',

// const apiAxios = axios.create({
//   baseURL: "https://335fb81d0256.ngrok.io",
// });

export const REGISTER_URL = "https://huaysports.com/home/register?ref=b74574fa-1f94-4101-8055-91386d51efab";
export const OFFICIAL_URL = "https://line.me/R/ti/p/@844iihll?ts=01120200&oat_content=url";
export const GROUP_URL =
  "https://line.me/ti/g2/521lGW7MVPTpcFVgAFgv82dOWPgujdvNKv4_7Q?utm_source=invitation&utm_medium=link_copy&utm_campaign=default";
export const SOCKET_URL = url;
const LOGIN_URL = "/login";
const MOCK_LOGIN_URL = "/create_mock_user";
const USER_TRANSACTION_URL = "/user_transaction";
const USER_BOT_TRANSACTION_URL = "/user_bot_transaction";
const USER_BOT_URL = "/user_bot";
const WALLET_URL = "/wallet";
const BOT_TRANSACTION_URL = "/bot_transaction";
const BOT_URL = "/bot";
const START_URL = "/start";
const PAUSE_URL = "/pause";
const STOP_URL = "/stop";
const SET_OPPOSITE_URL = "/bot/set_opposite";
const USER_PROFILE = "/profile";
const BOT_INFO = "/bot_info";
const GETHISBOT = "/getHisbot";
const SET_BET_SIDE_URL = "/bot/set_bet_side";
const WITHDRAW_ROLLING_URL = "/rolling_withdraw";
const SET_ZERO_BET_URL = "/bot/set_zero";
const SAVE_DATA_URL = "/save";
const ADD_DATA_URL = "/create";
const DELETE_DATA_URL = "/delete";
const BOT_URL_AGAIN = "/bot_again"


export function login(data) {
  return apiAxios.post(LOGIN_URL, data);
}
export function getLastBot(id) {
  return apiAxios.get("/last/" + id);
}
// export function saveBot(id) {
//   return apiAxios.get("/save/" + id);
// }
export function getRoom() {
  return apiAxios.get("/get-room");
}
export function maintenence() {
  return apiAxios.get("/maintenence");
}
export function Rank() {
  return apiAxios.get("/ranking");
}
export function marting(id) {
  return apiAxios.get("/martingale?userID=" + id);
}

export function mockLogin(data) {
  return apiAxios.post(MOCK_LOGIN_URL, data);
}

export function getWallet(id) {
  return apiAxios.get(WALLET_URL + "/" + id);
}

export function getBotTransaction(params) {
  return apiAxios.get(BOT_TRANSACTION_URL, params);
}

export function getBotTransactionAll(params) {
  return apiAxios.get("/bot_transaction_all/" + params);
}

export function getUserBotTransaction(id) {
  return apiAxios.get(USER_BOT_TRANSACTION_URL + "/" + id);
}

export function getUserTransaction(id, params) {
  return apiAxios.get(USER_TRANSACTION_URL + "/" + id, params);
}

export function getUsetBot(id) {
  return apiAxios.get(USER_BOT_URL + "/" + id);
}

export function createBot(data) {
  return apiAxios.post(BOT_URL, data);
}

export function startBot(data) {
  return apiAxios.post(START_URL, data);
}

export function pauseBot(data) {
  return apiAxios.post(PAUSE_URL, data);
}

export function stopBot(data) {
  return apiAxios.post(STOP_URL, data);
}

export function setOppositeBot(data) {
  return apiAxios.post(SET_OPPOSITE_URL, data);
}

export function getUserProfile(param = {}) {
  return apiAxios.get(USER_PROFILE, param);
}

export function setBetSide(data) {
  return apiAxios.post(SET_BET_SIDE_URL, data);
}

export function withdrawRolling(data) {
  return apiAxios.post(WITHDRAW_ROLLING_URL, data);
}

export function setZero(data) {
  return apiAxios.post(SET_ZERO_BET_URL, data);
}

export function getBotInfo(userId, botId) {
  return apiAxios.get(BOT_INFO + "/" + userId + "/" + botId);
}

export function getHisbot() {
  return apiAxios.get(GETHISBOT);
}

export const saveData = (id) => {
  return apiAxios.get(SAVE_DATA_URL + "/" + id);
};

export const addData = (id, data) => {
  return apiAxios.post(ADD_DATA_URL + "/" + id, data);
};

export const DeleteData = (id, data) => {
  return apiAxios.delete(DELETE_DATA_URL + "/" + id, data);
};

export const BotAgain = (data) => {
  return apiAxios.post(BOT_URL_AGAIN, data)
}